import * as Color from "color";

const blue = Color("#65c8d0");
const blueLight = Color("#B9CED0");
const blueDark = Color("#242D30");
const blueMedium = Color("#2E3D3D");

const yellow = Color("#ddc560");
const yellowLight = Color("#F2ECD2");
const yellowBold = Color("#DDC560");
const yellowDark = Color("#3B3930");

const pink = Color("#dd60b0");
const purpleDark = Color("#291F26");
const purpleMedium = Color("#2E222A");
const pinkLight = Color("#DDC7D5");

// export { blue, yellow, pink, yellowLight, yellowBold, purpleDark, purpleMedium, pinkLight };
export default {
  blueDark,
  blueMedium,
  blue,
  blueLight, // see flux, lol.
  yellow,
  pink,
  yellowDark,
  yellowLight,
  yellowBold,
  purpleDark,
  purpleMedium,
  pinkLight
};
