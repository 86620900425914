import { Link } from "gatsby";
import React from "react";
import Icon from "./icon";
import { cn } from "../lib/helpers";
import styled from "styled-components";
import colors from "../styles/colors";

const LogoDiv = styled.div`
  margin: 48px;
  text-align: center;
  a {
    font-size: 28px;
    color: white;
  }
`;

const Header = ({ onHideNav, onShowNav, showNav, siteTitle }) => (
  <div>
    <div>
      <LogoDiv>
        <Link to="/">{siteTitle}</Link>
      </LogoDiv>

      {/*
      <button className={styles.toggleNavButton} onClick={showNav ? onHideNav : onShowNav}>
        <Icon symbol="hamburger" />
      </button>

      <nav className={cn(styles.nav, showNav && styles.showNav)}>
        {/*
        <ul>
          <li>
            <Link to="/archive/">Archive</Link>
          </li>
        </ul>
      </nav>
  */}
    </div>
  </div>
);

export default Header;
