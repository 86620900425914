import { format } from "date-fns";
import { Link } from "gatsby";
import React from "react";
import { buildImageObj, cn, getBlogUrl } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";
import PortableText from "./portableText";
import styled from "styled-components";
import colors from "../styles/colors";

const H3 = styled.h3`
  color: ${colors.yellowBold};
  color: ${colors.blueLight.hex()};
  text-decoration: none;
  box-shadow: inset 0 -5px 0 ${colors.blueMedium.hex()};
  color: ${colors.blue.hex()};
`;

const PreviewContainer = styled.div`
  &:hover {
    h3 {
      box-shadow: inset 0 -5px 0 ${colors.pinkLight.hex()};
    }
  }
`;

function BlogPostPreview(props) {
  return (
    <PreviewContainer>
      <Link to={getBlogUrl(props.publishedAt, props.slug.current)}>
        <div>
          <H3>{props.title}</H3>
          {props._rawExcerpt && (
            <div>
              <PortableText blocks={props._rawExcerpt} />
            </div>
          )}
          <div>{format(props.publishedAt, "MMMM Do, YYYY")}</div>
        </div>
      </Link>
    </PreviewContainer>
  );
}

export default BlogPostPreview;
