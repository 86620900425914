import styles from "../styles/index.css";
import React from "react";
import Header from "./header";
import styled from "styled-components";

import colors from "../styles/colors";

import GlobalStyles from "./global-styles";

const ContainerA = styled.div`
  width: 100%;
  display: flex;
`;

const ContainerB = styled.div`
  font-size: 24px;
  margin: 0 auto;
  max-width: 900px;
  padding-left: 24px;
  padding-right: 24px;
`;

const Container = styled.div`
  margin: 3rem auto;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${colors.purpleDark};
  color: ${colors.yellowLight};
`;

const Footer = styled.footer`
  padding: 24px;
  width: 100%;
  text-align: center;
  font-size: 16px;
`;

const Layout = ({ children, onHideNav, onShowNav, showNav, siteTitle }) => (
  <ContainerA>
    <ContainerB>
      <GlobalStyles />
      <Header siteTitle={siteTitle} onHideNav={onHideNav} onShowNav={onShowNav} showNav={showNav} />
      <div>{children}</div>
      <Footer>
        <div>
          <div>&copy; {new Date().getFullYear()} Hunter Kievet</div>
        </div>
      </Footer>
    </ContainerB>
  </ContainerA>
);

export default Layout;
