import { createGlobalStyle, keyframes } from "styled-components";
import colors from "../styles/colors";

const fadeIn = keyframes`
  from{ opacity:0 ;
  }
  to {
     opacity: 1;
  }
`;

// font-family: 'Open Sans', sans-serif;
const GlobalStyles = createGlobalStyle`
  #___gatsby{
    margin: 0 auto;
  }
  ::selection {
    color: ${colors.yellowDark.hex()};
    background: ${colors.yellowBold.hex()};
  }
  body {
    animation-name: ${fadeIn};
    animation-duration: 3s;
    animation-timing-function: ease-in-out;
    background-color: ${props => colors.purpleDark.hex()};
    color: ${props => colors.blueLight.hex()};
    font-size: 16px;
    font-weight: 100;
    margin: 0;
    padding: 0;
    height: 100vh;
    display: flex;
    font-family: casablanca-urw, sans-serif;
    font-style: normal;
    margin: 0 auto;
  }
  a {
    color: ${colors.blueLight.hex()};
    text-decoration: none;
    box-shadow: inset 0 -5px 0 ${colors.blueMedium.hex()};
      color: ${colors.blue.hex()};
    &:hover {
      background-color: ${colors.blueLight.hex()};
      box-shadow: inset 0 -5px 0 ${colors.yellowDark.hex()};
      color: ${colors.yellowDark.hex()};
      cursor: pointer;
    }
  }
  h1,h2,h3,h4,h5,h6 {
    font-weight: normal;
  }

  h1 {
    font-size:28px; 
    color: ${colors.yellowBold.hex()};
    padding-top: 24px;
  }
  h2 {
    font-size:24px; 
    font-weight:1;
    color: ${colors.yellowBold.hex()};
    padding-top: 12px;
  }
  p {
    line-height: 36px;
  }


  html {
  box-sizing: border-box;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }
`;
export default GlobalStyles;
